exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contacto-tsx": () => import("./../../../src/pages/contacto.tsx" /* webpackChunkName: "component---src-pages-contacto-tsx" */),
  "component---src-pages-detekta-tsx": () => import("./../../../src/pages/detekta.tsx" /* webpackChunkName: "component---src-pages-detekta-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-industrias-tsx": () => import("./../../../src/pages/industrias.tsx" /* webpackChunkName: "component---src-pages-industrias-tsx" */),
  "component---src-pages-nosotros-tsx": () => import("./../../../src/pages/nosotros.tsx" /* webpackChunkName: "component---src-pages-nosotros-tsx" */),
  "component---src-pages-soluciones-comerciales-tsx": () => import("./../../../src/pages/soluciones-comerciales.tsx" /* webpackChunkName: "component---src-pages-soluciones-comerciales-tsx" */),
  "component---src-pages-soluciones-de-recaudo-tsx": () => import("./../../../src/pages/soluciones-de-recaudo.tsx" /* webpackChunkName: "component---src-pages-soluciones-de-recaudo-tsx" */),
  "component---src-templates-blog-template-tsx": () => import("./../../../src/templates/blogTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-template-tsx" */)
}

